import getConfig from 'next/config';
import { createClient, Exchange } from 'urql';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const urqlClient = (exchanges?: Exchange[], serverSide?: boolean) =>
  createClient({
    url: `${
      serverSide && serverRuntimeConfig.API_ORIGIN
        ? serverRuntimeConfig.API_ORIGIN
        : publicRuntimeConfig.API_ORIGIN
    }/graphql`,
    maskTypename: true,
    requestPolicy: 'network-only',
    ...(exchanges !== undefined && exchanges),
    fetchOptions: {
      credentials: 'include',
    },
  });

export default urqlClient;
