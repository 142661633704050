import { createContext, useContext } from 'react';
import { OrganisationEntityDto } from '../types/graphql';

type OrganisationContextDto = Pick<OrganisationEntityDto, 'id' | 'name'>;

export interface OrganisationContextProps {
  organisation?: OrganisationContextDto;
  updateOrganisation?: (organisation: OrganisationContextDto, replace?: boolean) => void;
}

const OrganisationContext = createContext<OrganisationContextProps>({
  organisation: undefined,
  updateOrganisation: undefined,
});

const useOrganisationContext = () => useContext(OrganisationContext);
export { OrganisationContext, useOrganisationContext };
