import { OrganisationContext, OrganisationContextProps } from '@/lib/context/organisation';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enAU, enCA, enGB, enNZ, enUS, Locale } from 'date-fns/locale';
import TT_THEME from 'lib/utils/theme';
import { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import { ReactElement, ReactNode, useRef, useState } from 'react';
import 'styles/global.scss';
import { Provider as UrqlProvider } from 'urql';
import urqlClient from '../lib/utils/urql-client';
import getConfig from 'next/config';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const {
  publicRuntimeConfig: { GTM_ENABLED },
} = getConfig();

const SUPPORTED_LOCALES: { [locale: string]: Locale } = {
  'en-AU': enAU,
  'en-NZ': enNZ,
  'en-US': enUS,
  'en-GB': enGB,
  'en-CA': enCA,
};

const SNACKBAR_AUTO_HIDE_DURATION = 2000;
const SNACKBAR_ACTIONS = (provider: SnackbarProvider | null, snackbarId: string | number) => (
  <IconButton onClick={() => provider?.closeSnackbar(snackbarId)} sx={{ color: '#FFF' }}>
    <Close />
  </IconButton>
);

const client = urqlClient();

export type NextPageWithLayout<T> = NextPage<T> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component?: NextPageWithLayout<any>;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { getLayout } = Component || {};
  const [organisation, setOrganisation] = useState<OrganisationContextProps['organisation']>();
  const router = useRouter();
  const snackBarRef = useRef<SnackbarProvider>(null);
  let pageContents;

  if (getLayout) {
    pageContents = (
      <SnackbarProvider
        ref={snackBarRef}
        maxSnack={3}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        action={(id) => SNACKBAR_ACTIONS(snackBarRef?.current, id)}
      >
        <ThemeProvider theme={TT_THEME}>
          <UrqlProvider value={client}>{getLayout(<Component {...pageProps} />)}</UrqlProvider>
        </ThemeProvider>
      </SnackbarProvider>
    );
  } else {
    pageContents = (
      <SnackbarProvider
        ref={snackBarRef}
        maxSnack={3}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        action={(id) => SNACKBAR_ACTIONS(snackBarRef?.current, id)}
      >
        <ThemeProvider theme={TT_THEME}>
          <UrqlProvider value={client}>{<Component {...pageProps} />}</UrqlProvider>
        </ThemeProvider>
      </SnackbarProvider>
    );
  }

  const updateOrganisation = (
    updatedOrganisation: OrganisationContextProps['organisation'],
    replace = false,
  ) => {
    if (organisation?.id !== updatedOrganisation?.id) {
      const query = { ...router.query };
      if (updatedOrganisation) {
        query.organisationId = `${updatedOrganisation.id}`;
      }
      if (replace) {
        router.replace({ query });
      } else {
        router.push({ query });
      }
    }
    setOrganisation(updatedOrganisation);
  };

  const getLocale = () => {
    const { locale } = Intl.DateTimeFormat().resolvedOptions();
    let dateFnsLocale = enUS;
    if (Object.keys(SUPPORTED_LOCALES).includes(locale)) {
      dateFnsLocale = SUPPORTED_LOCALES[locale];
    }
    return dateFnsLocale;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
      <OrganisationContext.Provider value={{ organisation, updateOrganisation }}>
        {GTM_ENABLED && (
          <>
            <Head>
              <script
                id="gtm-head"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WVKPRLF');`,
                }}
              ></script>
            </Head>
            <noscript
              dangerouslySetInnerHTML={{
                __html: `
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WVKPRLF"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
              }}
            ></noscript>
          </>
        )}
        {pageContents}
      </OrganisationContext.Provider>
    </LocalizationProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};
export default MyApp;
